import { fallback, zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

const schema = z.object({
  agreementType: fallback(z.enum(['user', 'essential']), 'user').default(
    'user'
  ),
})

export const termsSearchSchema = zodValidator(schema)
export type TermsSearchSchema = z.infer<typeof schema>
