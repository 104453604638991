import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

const schema = z.object({
  acceptedStoragePermissions: z.boolean().optional(),
  acknowledgedStoragePermissionsPopup: z.boolean().optional(),
})

export const homeSearchSchema = zodValidator(schema)
export type HomeSearchSchema = z.infer<typeof schema>
