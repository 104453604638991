import '@fontsource-variable/reddit-sans'

import {
  GlobalStyle,
  initializeSmartyStreets,
  WillowThemeProvider,
} from '@branch-messenger/willow-ui'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { router } from '@/lib/router'

import { initializeAnalytics } from './lib/analytics.ts'
import { queryClient } from './lib/queryClient.ts'
import { initializeSentry } from './lib/sentry.ts'

initializeSentry()
initializeAnalytics()
initializeSmartyStreets(import.meta.env.VITE_SMARTY_AUTH_ID)

async function enableMocking() {
  if (!import.meta.env.DEV) {
    return
  }

  const { worker } = await import('./mocks/browser')
  window.localStorage.removeItem('__msw-cookie-store__') // clear msw cookie store
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}
void enableMocking().then(() => {
  const rootElement = document.getElementById('root')!
  if (!rootElement.innerHTML) {
    const root = createRoot(rootElement)
    root.render(
      <QueryClientProvider client={queryClient}>
        <WillowThemeProvider>
          <StrictMode>
            <RouterProvider router={router} />
          </StrictMode>
          <GlobalStyle />
        </WillowThemeProvider>
      </QueryClientProvider>
    )
  }
})
